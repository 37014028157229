// App.tsx

import React, { useState, useEffect } from 'react';

import { Switch, TreeSelect, Button, Form, Space, message } from 'antd';
import { DatabaseOutlined, GlobalOutlined, ApartmentOutlined, PlusCircleOutlined, MinusCircleOutlined, SendOutlined, LoadingOutlined } from '@ant-design/icons';

import { Idea } from './Idea';

import axios from 'axios';

const { Item } = Form;

export const Input: React.FC<any> = (parent) => {

    const [tags, setTags] = useState();
    const [agent, setAgent] = useState(parent.agent || 'chat');
    const [agentSwitch, setAgentSwitch] = useState((parent.agent || 'chat') === 'chat');
    const [tagTree, setTagTree] = useState<any>([]);

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const [webSearch, setWebSearch] = useState<boolean>(false);

    useEffect(() => {
        if (parent.formRef.current) {
            parent.formRef.current.setFieldsValue({ websearch: webSearch });
        }
    }, [webSearch]);

    useEffect(() => {
        if (parent.formRef.current) {
            parent.formRef.current.setFieldsValue({ agent: agent });
        }
    }, [agent]);

    useEffect(() => {
        if(parent.agent) 
            setAgent(parent.agent)
    }, [parent.agent]);

    useEffect(() => {
        axios.get(
            `${azure_function_url}/LLMModel?command=${parent.tree === true ? "tree" : "tags" }&context=${parent.context}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                if (res.data !== null && res.data !== undefined && res.data?.length > 0)
                    setTagTree(res.data)
            })
            .catch(err => {
                console.log(err);
                message.error(err.toString())
            });

    }, [parent.context, parent.thread, parent.updateCounter]);

    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        // const textarea = event.currentTarget;

        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // prevent creating a new line
            parent.handleMessageSend();
        }

        // else if (event.key === 'Tab') {
        //     event.preventDefault();

        //     const spaces = "    "; // 4 spaces for a tab, you can adjust this
        //     const start = textarea.selectionStart;
        //     const end = textarea.selectionEnd;
        //     const selectedText = textarea.value.substring(start, end);

        //     if (start !== end) {
        //         // Text is selected. Indent each line of the selected text.
        //         const indentedText = selectedText.split('\n').map(line => spaces + line).join('\n');
        //         textarea.value = textarea.value.substring(0, start)
        //             + indentedText
        //             + textarea.value.substring(end);
                
        //         // Adjust the selection to highlight the newly indented text
        //         textarea.selectionStart = start;
        //         textarea.selectionEnd = start + indentedText.length;
        //     } else {
        //         // No text selected. Insert spaces for a tab.
        //         textarea.value = textarea.value.substring(0, start)
        //             + spaces
        //             + textarea.value.substring(end);

        //         // Position the caret after the inserted spaces
        //         textarea.selectionStart = textarea.selectionEnd = start + spaces.length;
        //     }
        // }
    };
  
    return (
        <div style={{ 
            display: 'flex', 
            
            flexDirection: 'column', // Added for vertical alignment
            alignItems: 'center', // Center items horizontally
            justifyContent: 'center', // Center items vertically

            width: '100%',
            boxSizing: 'border-box',
            backgroundColor: 'white',
            borderRadius: '15px 15px', 
            border: '1px solid rgba(0, 0, 0, 0.75)', 

            position: parent.bottom === true ? 'absolute' : undefined ,
            bottom: parent.bottom === true ? '0px' : undefined 
        }}>
            <Form 
                ref={parent.formRef} 
                style={{ 
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'row', 
                    margin: '10px' 
                }} 
                initialValues={{prompts:[{prompt:''}]}}
            >
                <Button 
                    type="primary" 
                    icon={(parent.isLoadingChat ? <LoadingOutlined />:<></>)} 
                    style={{ 
                        color: 'rgba(0, 0, 0, 0.75)',
                        backgroundColor: 'transparent', 
                        borderColor: 'transparent', 
                        borderRadius: '0 0 0 0',
                        alignSelf: 'flex-end',
                        marginBottom: '0px',
                        boxShadow:'none'
                    }}
                />
                
                <Item style={{ flexGrow: 1, marginBottom: 0, marginRight: '-1px' }}>
                    <div ref={parent.ref_input} style={{ width: '100%' }}>
                        <div ref={parent.messageInputRef} ></div>
                        <Form.List name="prompts">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div key={key} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                            { agent === 'beta' && (key > 0 ? (<MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: '8px', marginRight: '10px', marginBottom: '12px', color: 'red' }} />) : (<PlusCircleOutlined onClick={() => add()} style={{ marginLeft: '8px', marginRight: '10px', marginBottom: fields.length === 1 ? undefined : '12px' }} />))}
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'prompt']}
                                                style={{ flex: 1, marginBottom: fields.length === 1 ? 0 : undefined, maxHeight: '400px', overflow: 'auto' }}
                                            >
                                                <Idea
                                                    placeholder={ "How can I help you?" }
                                                    disabled={parent.isLoadingChat}
                                                    noborder
                                                    handleKeyDown={handleKeyDown}
                                                    agent={agent}
                                                    tags={tags}
                                                />
                                            </Form.Item>
                                        </div>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </div>
                </Item>
                
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Space.Compact 
                        style={{ 
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexGrow: 1,
                            marginBottom: 0,
                            marginRight: '-1px',
                        }}
                    >
                        <Item name={'websearch'} noStyle>
                            <Space direction="vertical">
                                <Switch
                                    style={{marginTop:'4px', marginRight: '10px'}}
                                    checkedChildren={<GlobalOutlined />}
                                    unCheckedChildren={<GlobalOutlined />}
                                    checked={webSearch} 
                                    onChange={()=>{
                                        setWebSearch(!webSearch);
                                    }}
                                />
                            </Space>
                        </Item>
                        <div ref={parent.ref_agent}>
                        { !parent.isMobile && parent.prompt === undefined && (
                            <Item name={'agent'} noStyle>
                                <Space direction="vertical">
                                    <Switch
                                        style={{marginTop:'4px'}}
                                        checkedChildren={<DatabaseOutlined/>}
                                        unCheckedChildren={<DatabaseOutlined />}
                                        checked={agentSwitch} 
                                        onChange={()=>{
                                            setAgent(agentSwitch ? 'GPT4' : 'chat');
                                            setAgentSwitch(!agentSwitch);
                                        }}
                                    />
                                </Space>
                            </Item>
                        )}
                        </div>
                        { agentSwitch && parent.prompt === undefined && tagTree && tagTree.length > 0 && (
                        <div ref={parent.ref_tags}>
                        <Item name={'tags'} noStyle>
                            <TreeSelect
                                style={{ 
                                    minWidth: '50px',
                                }} 
                                dropdownStyle={{ 
                                    minWidth: parent.isMobile ? '300px' : '450px',
                                    maxWidth: '50%',
                                }}
                                // placeholder={'Select tags...'}
                                placeholder={<ApartmentOutlined />}
                                multiple={true}
                                onChange={e => setTags(e)} 
                                treeData={tagTree}
                            />  
                        </Item>
                        </div>
                        )}
                        
                    </Space.Compact>
                    <Button
                        ref={parent.ref_send}
                        type="primary" 
                        icon={<SendOutlined />} 
                        onClick={parent.handleMessageSend}
                        style={{ 
                            color: 'rgba(0, 0, 0, 0.75)',
                            backgroundColor: 'transparent'
                        }}
                    />
                </div>
            </Form>
        </div>
    );
}
// App.tsx

import React, { useState, useEffect, useReducer } from 'react';

import { Upload, DatePicker, Table, Menu, Popconfirm, Card, FloatButton, Input, Button, Tabs, message } from 'antd';
import type { UploadProps, MenuProps } from 'antd';

import { SearchOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, KeyOutlined, UploadOutlined, CloudDownloadOutlined, DatabaseOutlined, CloseOutlined, CheckOutlined, EllipsisOutlined, PlusCircleOutlined, TeamOutlined, MessageOutlined, DeleteOutlined} from '@ant-design/icons';

import { Employees } from '../employees/Employees';

import axios from 'axios';

import { SIKE } from '../sike/SIKE';
import { PromptFlow } from '../sike/PromptFlow';

import dayjs from 'dayjs';

const { Dragger } = Upload;


const { Column } = Table;

type MenuItem = Required<MenuProps>['items'][number];

const InputLabel: React.FC<any>  = (parent: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(parent.label);
    const [lastValue, setLastValue] = useState(parent.label);

    useEffect(()=>{
        setEditValue(parent.label)
        setLastValue(parent.label)
    },[parent.label])

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    return (
        parent.keyVal === '_search_' ?
            <div 
                style={{ 
                    display: 'flex', 
                    width: '100%', 
                    
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    
                    borderRadius: '15px 15px', 
                    border: '1px solid rgba(0, 0, 0, 0.25)', 
                    margin: '10px 10px 20px 0px' 

                }}>
                <Input
                    style={{
                        marginLeft:'7px', 
                        border: '0px',
                    }}
                    autoFocus
                    onChange={(e) => {
                        e.stopPropagation();
                        parent.setSearch(e.target.value);
                    }}
                />
                <Button
                    type="text"
                    icon={<SearchOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                        setLastValue(editValue);

                        console.log({
                            id: parent.keyVal,
                            name: editValue
                        })

                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                            {
                                id: parent.keyVal,
                                name: editValue
                            },
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            }
                        ).then(res => {
                            console.log(res);
                        }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
            </div>
        :
        isEditing ?
            <div style={{ display: 'flex', width: '100%', padding: '0px !important' }}>
                <Input
                    style={{marginLeft:'7px' }}
                    autoFocus
                    defaultValue={editValue}
                    onChange={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                    }}
                    onPressEnter={(e) => {
                            e.stopPropagation();
                            axios.post(
                                `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                                {
                                    name: editValue
                                },
                                {
                                    headers: { 'Authorization': parent.bearerToken }
                                }
                            ).then(res => {
                                setIsEditing(false);
                            });
                        
                    }}
                    onBlur={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                            {
                                name: e.target.value
                            },
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            }
                        ).then(res => {
                            setIsEditing(false);
                        });
                    }}
                />
                <Button
                    type="text"
                    icon={<CheckOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                        setLastValue(editValue);

                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                            {
                                name: editValue
                            },
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            }
                        ).then(res => {
                            console.log(res);
                        }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditValue(lastValue);
                        setIsEditing(false);
                    }}
                />
            </div>
        :
            <div 
                style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    width:'100%',
                    borderRadius: '7px',
                    height: '70px',
                    paddingLeft: parent.selectedThread === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '9px' : '12px', // adjust padding if needed when selected
                    borderLeft: parent.selectedThread === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '3px solid #d9d9d9' : undefined, // add a left border to indicate selection
                    backgroundColor: parent.selectedThread === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '#f5f5f5' : undefined, // light blue background for selection, you can choose any color you prefer
                }}
            >
                {parent.isAddThread ? <></>:parent.menuType === 'threads'?<MessageOutlined/>:<UserOutlined/>}
                <a 
                    style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: parent.keyVal === 'add_thread' || parent.keyVal === 'add_team' ? '' : '10px' }}
                    onClick={(e)=>{

                        if(parent.keyVal === 'add_thread' || parent.keyVal === 'add_team')
                            parent.handleAddClick(e);
                        else if(parent.menuType === 'threads'){
                            parent.setThread(parent.keyVal);
                            parent.setLabel(parent.label);
                        }
                        else{
                            parent.setPrompt(parent.keyVal);
                        }
                        
                    }}
                >
                    {editValue}
                </a>
                {parent.isAddThread ? 
                    <Button
                        type="text"
                        icon={<PlusCircleOutlined style={{ flexShrink: 1 }}/>}
                        onClick={parent.handleAddClick}
                    /> 
                : parent.menuType === 'threads' ?
                    <>
                        <Button
                            type="text"
                            icon={<EllipsisOutlined style={{ flexShrink: 1 }} />}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent the menu from triggering its default behavior
                                setIsEditing(true);
                            }}
                        />
                        { parent.selectedThread === parent.keyVal ? 
                        <Popconfirm
                            title="Delete Thread"
                            description="Would you like to delete the thread?"
                            onConfirm={(e) => {
                                setIsEditing(false);

                                axios.post(
                                    `${azure_function_url}/LLMModel?command=context_thread_delete&context=${parent.context}&thread=${parent.keyVal}`,
                                    {
                                        name: editValue
                                    },
                                    {
                                        headers: { 'Authorization': parent.bearerToken }
                                    }
                                ).then(res => {
                                    setIsEditing(false);
                                    parent.reload();
                                }).catch(err => {console.log(err);message.error(err.toString())});
                            }}
                            onCancel={() => { }}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            
                        />
                        </Popconfirm>
                        :<></>
                        }
                    </>
                :<></>
                }
            </div>
    );
};

const insertItemInTree = (root: any[], parts: string[], item: any, pathSoFar = '') => {
    let currentLevel = root;
    parts.forEach((part, index) => {
        // Update the path so far with the current part
        const currentPath = pathSoFar + (index > 0 ? '/' : '') + part;

        if (index < parts.length - 1) {
            // We are dealing with a directory
            let directory = currentLevel.find(child => child.name === part);
            if (!directory) {
                directory = { name: part, children: [], key: currentPath };
                currentLevel.push(directory);
            }
            currentLevel = directory.children!;
        } else {
            // We are dealing with a file (leaf)
            currentLevel.push({ ...item, name: part, key: currentPath });
        }
    });
};

const transformFileList = (fileList: any[]): any[] => {
    const root: any[] = [];

    fileList.forEach((item) => {
        const parts = item.name.split('/');
        insertItemInTree(root, parts, item);
    });

    return root;
};

export const Knodules: React.FC<any> = (parent) => {

    const [thread, setThread] = useState('-');
    const [label, setLabel] = useState('');
    const [search, setSearch] = useState('');
    const [activeTabKey, setActiveTabKey] = useState('chat'); // Default active tab key
    const [menuType, setMenuType] = useState('threads'); // Default active tab key
    const [prompt, setPrompt] = useState<string>(); // Default active tab key
    const [flows, setFlows] = useState<Record<string,any>>(); // Default active tab key
    const [openKeys, setOpenKeys] = useState(['1']);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const [isLoadingFiles, setLoadingFiles] = useState({ state: true});
    const [fileList, setFileList] = useState<any[]>();
    const [documentType, setDocumentType] = useState<string>("8191");

    const [context, setContext] = useState<string>(parent.context);
    const [sikeUpdateCounter, setSikeUpdateCounter] = useState(0); // Default active tab key
    const [openMenu, setOpenMenu] = useState(!parent.isMobile);

    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const loadThreadList = (set_thread = true) => {
        axios.get(
            `${azure_function_url}/LLMModel?command=context_thread_list&context=${context}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                const it = res.data.map((x:any) => getItem(x.thread === '_flows_' ? 'Workflows' : x.name, x.thread));
                it.unshift(getItem('', '_search_', (e: React.Key) => handleAddOnTop(e, it.length)));
                it.push(getItem('New Thread', 'add_thread', (e: React.Key) => handleAddOnTop(e, it.length)));
                setMenuItems(it);
                forceUpdate();
                
                if(set_thread && res.data.length > 0){
                    setThread(res.data[0].thread);
                    setLabel(res.data[0].name);
                }
            })
            .catch(err => {console.log(err);message.error(err.toString())});
    }

    const loadFlowList = (set_thread = true) => {
        axios.get(
            `${azure_function_url}/LLMModel?command=flow_list&context=${context}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                const records = res.data.reduce((acc: Record<string, any>, curr: any) => {
                    const key = String(curr.id); // Ensuring the key is a string
                    acc[key] = curr;
                    return acc;
                }, {});
                setFlows(records);
                const it = res.data.map((x:any) => getItem(x.name, x.id));
                it.push(getItem('New Team', 'add_team', (e: React.Key) => handleAddOnTop(e, it.length)));
                setMenuItems(it);
                if(set_thread && res.data.length > 0 || prompt === undefined)
                   setPrompt(res.data[0]?.data?.id);                
            })
            .catch(err => {console.log(err);message.error(err.toString())});
    }

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        addOnTop?: (key: React.Key) => void,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group'
    ): MenuItem => {   
        // Function to call when the Add Thread button is clicked
        const handleAddClick = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation(); // Prevent triggering click on menu item
            addOnTop && addOnTop(key);
        };
      
        // If the key is 'add_thread', we want to add an "Add" button instead of an "Edit" button
        const isAddThread = key === 'add_thread' || key === 'add_team';
        
        return {
            key,
            label: <InputLabel search={search} setSearch={setSearch} bearerToken={parent.bearerToken} menuType={menuType} context={context} label={label} keyVal={key} selectedThread={thread} selectedPrompt={prompt} isAddThread={isAddThread} handleAddClick={handleAddClick} setThread={setThread} setLabel={setLabel} setPrompt={setPrompt} reload={loadThreadList}/>,
            type,
        } as MenuItem;
    }
    
    const handleAddOnTop = (key: React.Key,num : number) => {
        if (key === 'add_thread') {
            const name = `Thread ${num}`;
            const id = `${Date.now()}`
            const newItem = getItem(name, id);

            axios.post(
                `${azure_function_url}/LLMModel?command=context_thread_update&context=${context}&thread=${id}`,
                {
                    name: name
                },
                {
                    headers: { 'Authorization': parent.bearerToken }
                }
                )
                .then(res => {
                    setMenuItems((prevItems) => {
                        // Find the index of 'Add Thread'
                        const addThreadIndex = prevItems.findIndex(item => item?.key === 'add_thread');
                        // Insert new item just above 'Add Thread'
                        const newItems = [...prevItems];
                        newItems.splice(addThreadIndex, 0, newItem);
                        return newItems;
                    });

                    setThread(id);
                    setLabel(name);
                }).catch(err => {console.log(err);message.error(err.toString())});       
        }
        else{
            setPrompt('add_');
        }
    };

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    useEffect(() => {
        if(menuType === 'threads')
            loadThreadList(thread === '-');
        else
            loadFlowList(false);

    },[context, thread, prompt, menuType]);

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        accept: '.pdf, .ts, .md, .docx',
        defaultFileList: fileList,
        customRequest: async (options: any) => { 
            
            // eslint-disable-next-line
            const { onSuccess, onError, file, onProgress } = options;
            const buffer = await (file as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);

            const fmData = new FormData();

            setLoadingFiles({ state: true });
    
            fmData.append("file", new Blob([bytes], {type:file.type}), file.name);

            const res = await axios.post(
                `${azure_function_url}/LLMModel?command=files_upload&context=${context}&chunkSize=${documentType}`,
                fmData,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        "Content-Type": `multipart/form-data; boundery=${(fmData as any)._boundary}`,
                        'Authorization': parent.bearerToken
                    },
                }
            )

            const files = res.data.map((row: any, i: number) => {
                return {
                    uid: i,
                    id: row.id,
                    name: row.name,
                    status: 'done',
                    data: row.data,
                    added: row.added,
                    last_read: row.last_read,
                    initialChunkSize: row.initialchunksize
                };
            }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);

            setLoadingFiles({ state: false });
            setFileList(files);
            setSikeUpdateCounter(x=>x=x+1);

            if(onSuccess){
                onSuccess("Ok");
            }            
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            }
            else if (status === 'removed') {
                message.success(`${info.file.name} file removed successfully.`);
            }
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };
    
    let items = [
        {
            label: (
                <span style={{
                    fontSize: parent.isMobile ? undefined : '22px', 
                    fontWeight: 100
                }}>
                    <MessageOutlined />
                    {thread === '_flows_' ? 'Workflows' : label}
                </span>
                ),
            key: 'chat',
            children:
            <div 
                style={{ 
                    height: 'calc(100vh - 155px)'  
                }}
            >
                <SIKE 
                    bearerToken={parent.bearerToken} 
                    user={parent.user} 
                    context={context} 
                    thread={thread} 
                    permission={parent.permission} 
                    updateCounter={sikeUpdateCounter}
                    isMobile={parent.isMobile}
                />
            </div>
        },
        {
            label: (
                <span style={{
                    fontSize: parent.isMobile ? undefined : '22px', 
                    fontWeight: 100
                }}>
                    <DatabaseOutlined />
                    Data
                </span>
                ),
            key: 'data',
            children: 
                <>
                    <Table 
                        dataSource={fileList && transformFileList(fileList)?.map((x, i) => {
                            x.key = i;
                            return x;
                        })}
                        pagination={{hideOnSinglePage: true}}
                        loading={isLoadingFiles.state}
                    >
                        <Column
                            title="Name"
                            key="name"
                            render={(_: any, record: any) => (
                                record.name
                            )}
                        />
                        <Column
                            title="Added"
                            key="added"
                            render={(_: any, record: any) =>
                                (record.added ? <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Date.parse(record.added) / 1000)} /> : <></>
                            )}
                        />
                        <Column
                            title="Delete"
                            key="delete"
                            render={(_: any, record: any) => (
                                record.added && parent.permission !== 'WORKER' ? 
                                <Button 
                                    type="default" 
                                    block
                                    onClick={async ()=> { 
                                        setLoadingFiles({ state: true });
                                        const res = await axios.get(
                                            `${azure_function_url}/LLMModel?command=files_delete&id=${record.id}&context=${context}`,
                                            {
                                                headers: { 'Authorization': parent.bearerToken },
                                            });

                                        const files = res.data.map((row: any, i: number) => {
                                            return {
                                                uid: i,
                                                id: row.id,
                                                name: row.name,
                                                status: 'done',
                                                data: row.data,
                                                added: row.added,
                                                last_read: row.last_read,
                                                initialChunkSize: row.initialchunksize
                                            };
                                        }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);
                            
                                        setLoadingFiles({ state: false });
                                        setFileList(files);
                                        setSikeUpdateCounter(x=>x=x+1);
                                    }}
                                >
                                    <DeleteOutlined />
                                </Button>
                                :
                                <></>
                            )}
                        /> 
                        <Column
                            title="Download"
                            key="download"
                            render={(_: any, record: any) => (
                                record.added && parent.permission !== 'WORKER' ? 
                                <Button 
                                    type="default" 
                                    block
                                    onClick={async () => {
                                        const { data } = await axios.get(
                                            `${azure_function_url}/LLMModel?command=files_get&id=${record.id}&context=${context}`,
                                            {
                                                headers: { 'Authorization': parent.bearerToken}
                                            });

                                        const file = new File([Buffer.from(data.data,"base64")], record.name, {type: 'application/octet-stream'});
                                        const element = document.createElement("a");
                                        element.href = URL.createObjectURL(file);
                                        element.download = record.name;
                                        document.body.appendChild(element);
                                        element.click();
                                    }}
                                >
                                    <CloudDownloadOutlined />
                                </Button>
                                :
                                <></>
                            )}
                        />
                    </Table>
                    { parent.permission !== 'WORKER' && (
                    <div style={{height:'250px'}}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag some knowledge</p>
                            <p className="ant-upload-hint">
                            Only pdf files please.
                            </p>
                        </Dragger>      
                    </div> )}
                </>
        
    }];

    if(!parent.isMobile){
        items = items.concat({
            label: (
                <span style={{
                    fontSize: parent.isMobile ? undefined : '22px', 
                    fontWeight: 100
                }}>
                    <TeamOutlined />
                    Teams
                </span>
                ),
            key: 'teams',
            children: 
                <div style={{ height:'calc(100vh - 155px)' }}>
                    <PromptFlow bearerToken={parent.bearerToken} updateList={loadFlowList} user={parent.user} context={context} thread={'_flows_'} permission={parent.permission} updateCounter={sikeUpdateCounter} prompt={flows && prompt ? flows[prompt] : undefined} />
                </div>
        });
    }
    
    if(parent.permission !== 'WORKER' && parent.user.id !== context){
        items = items.concat(
            {
                label: (
                    <span style={{
                        fontSize: parent.isMobile ? undefined : '22px', 
                        fontWeight: 100
                    }}>
                        <KeyOutlined />
                        Permissions
                    </span>
                    ),
                key: 'permissions',
                children: 
                    <><Employees user={parent.user} bearerToken={parent.bearerToken} entityId={context} /></>
            }
        );
    }

    useEffect(() => {
        setLoadingFiles({ state: true });

        axios.get(
            `${azure_function_url}/LLMModel?command=files_list&context=${context}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                if(res.data){
                    const files = res.data.map((row: any, i: number) => {
                        return {
                            uid: i,
                            id: row.id,
                            name: row.name,
                            status: 'done',
                            data: row.data,
                            added: row.added,
                            last_read: row.last_read,
                            initialChunkSize: row.initialChunkSize
                        };
                    }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);
                    setLoadingFiles({ state: false });
                    setFileList(files);
                    if(parent.isMobile)
                        setOpenMenu(false);
                }
                else
                    setLoadingFiles({ state: false });
        })
        .catch(err => {console.log(err);message.error(err.toString())});

    }, [context, thread]);

    useEffect(() => {
        setThread('-');
        setLabel('');
        setSearch('');
    
        setContext(parent.context);   
        
        // axios.get(
        //     `${azure_function_url}/LLMModel?command=news_update`, 
        //     {
        //         headers: { 'Authorization': parent.bearerToken}
        //     })
        //     .then(x => { 
        //         console.log(x.data);
        //     });
        
        // axios.get(
        //     `${azure_function_url}/LayerC?command=balance&chainid=31337&currency=usdc&obligor=0x574D1135a10E91006eC937eFD2b29FC5B99F18a0`, 
        //     {
        //         headers: {
        //           'Authorization': parent.bearerToken
        //         }
        //     }).then(x => { 
        //         console.log(x.data);
        //         axios.get(
        //             `${azure_function_url}/Hutly?command=test2&chainid=31337&currency=usdc&amount=1000`, 
        //             {
        //                 headers: {
        //                   'Authorization': parent.bearerToken
        //                 }
        //             }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=test3&chainid=31337&currency=usdc&amount=1000`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=test&chainid=31337&currency=usdc&amount=1000`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=deposit_amount&chainid=31337&currency=usdc&amount=1000`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=request_credit&chainid=31337&currency=usdc&obligor=0x574D1135a10E91006eC937eFD2b29FC5B99F18a0&amount=1000`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=redeem_credit&chainid=31337&currency=usdc&amount=50`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=withdraw_amount&chainid=31337&currency=usdc&amount=5`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.post(
        //         //     `${azure_function_url}/LayerC?command=send_amount&chainid=31337&currency=usdc&obligor=0x574D1135a10E91006eC937eFD2b29FC5B99F18a0`, 
        //         //     {
        //         //         destination: '0x574D1135a10E91006eC937eFD2b29FC5B99F18a0',
        //         //         amount: 15,
        //         //     },
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.post(
        //         //     `${azure_function_url}/LayerC?command=retreive_amount&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         idhash: '21112703595377841928034256812283178714763988269873382168309281787118545069511'
        //         //     },
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=sales_contracts&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log('sales_contracts: ', x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=sales_authorities_contracts&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log('sales_authorities_contracts: ', x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=rentals_contracts&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log('rentals_contracts: ', x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=rentals_authorities_contracts&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log('rentals_authorities_contracts: ', x.data));
    

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=assets_get&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=liabilities_get&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x.data));

        //         // axios.post(
        //         // `${azure_function_url}/LayerC?command=create_deal&chainid=31337&currency=usdc`, 
        //         // {
        //         //     counterpart: '0x574D1135a10E91006eC937eFD2b29FC5B99F18a0',   
        //         //     data: { 
        //         //         // id: '0101',
        //         //         version: '1',
        //         //         type: 'test type',
        //         //         name: 'name 1',
        //         //         description: 'desc 1',
        //         //         some: 'thing' 
        //         //     },
        //         //     initial_payments: [
        //         //         {
        //         //             amount: '100'
        //         //         }, 
        //         //         {
        //         //             amount: '150'
        //         //         }, 
        //         //         // {
        //         //         //     amount: '200'
        //         //         // }, 
        //         //         // {
        //         //         //     amount: '250'
        //         //         // }
        //         //     ]
        //         // },
        //         // {
        //         //     headers: {
        //         //         'Authorization': parent.bearerToken
        //         //     }
        //         // }).then(x => console.log(BigInt(x.data).toString()));

        //         // axios.post(
        //         // `${azure_function_url}/LayerC?command=accept_deal&chainid=31337&currency=usdc`, 
        //         // {
        //         //     // counterpart: '0x574D1135a10E91006eC937eFD2b29FC5B99F18a0',
        //         //     idhash: 9
        //         // },
        //         // {
        //         //     headers: {
        //         //         'Authorization': parent.bearerToken
        //         //     }
        //         // }).then(x => console.log(x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=deal_get&chainid=31337&id=2`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x.data));
        //     });
    }, [parent.context]);
       
    return (
        <>
            <div style={{
                position: 'relative', 
                display: 'flex', 
                justifyContent: 'center', 
                height: 'calc(100vh - 65px)'
            }}>
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={(keys) => {
                        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                        if (latestOpenKey){
                            setOpenKeys(keys);
                        } else {
                            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
                        }
                    }}
                    style={{ 
                        display: openMenu ? undefined: 'none',
                        width: parent.isMobile ? '100%' : 400, 
                        fontSize: '25px',
                        fontWeight: 100,
                        
                        position: 'sticky',
                        top: parent.isMobile ? undefined : '100px', // Adjust the value as needed to position the menu correctly
                        overflowY: 'auto' 
                    }}
                    items={menuItems.filter((x:any)=> x.key === '_search_' || x.key === 'add_dream' || x.label.props.label.toLowerCase().indexOf(search?.toLowerCase()) > -1)}
                />
                <div 
                    className='custom-card' 
                    style={{
                        display: openMenu && parent.isMobile ? 'none' :  undefined,
                        width:  '100%', 
                        height: '100%',
                        marginRight: parent.isMobile ? undefined :  '55px', 
                        marginLeft: parent.isMobile || openMenu ? undefined:  '55px',
                    }}
                >
                    <Card 
                        bordered={false} 
                        className='custom-card'
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Tabs
                            activeKey={activeTabKey}
                            defaultActiveKey="1"
                            onChange={(key) => { 
                                if(key === 'teams')
                                    setMenuType('teams');
                                else
                                    setMenuType('threads');
                                setActiveTabKey(key); 
                            }}
                            type="card"
                            size={parent.isMobile ? "small" : "large"}
                            
                            items={items} 
                        />
                        
                    </Card>
                    
                </div>
                <FloatButton.Group
                        type="default"
                        style={{ 
                            left: 7, 
                            bottom: parent.isMobile ? (openMenu ? 20 : 90) : 20,
                            
                        }}
                    >
                    <FloatButton tooltip={<div>Menu</div>} icon={openMenu ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />} onClick={()=> {setOpenMenu(!openMenu);}} />
                </FloatButton.Group>
            </div>
        </>
    );
}
// App.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";

import { Input, Card, Space, Form, Modal, Badge, message, Upload, Avatar, Divider, Drawer, Breadcrumb, Button, Menu, Layout, Image, Row, Col } from 'antd';
import type { UploadProps } from 'antd';
import { BulbOutlined, PlusCircleOutlined, UploadOutlined, ApartmentOutlined, RadarChartOutlined, KeyOutlined, InfoCircleOutlined, MenuOutlined, CloseOutlined, FileSyncOutlined, UserOutlined, TeamOutlined, UndoOutlined, HomeOutlined, FileProtectOutlined, InboxOutlined, UnorderedListOutlined, ShoppingCartOutlined, LogoutOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Routes, Route } from 'react-router-dom';

import axios from 'axios';

// Login Start
import { useNavigate } from "react-router-dom";
import { MsalProvider, MsalAuthenticationTemplate, MsalAuthenticationResult, useMsal } from '@azure/msal-react';
import { InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { CustomNavigationClient } from "./utils/auth/NavigationClient";
import { loginRequest } from "./utils/auth/authConfig";
import { msalInstance } from "./index";
// Login End

import { parseJSON } from './utils/utils';
import type { MenuProps } from 'antd';

import { PointThreshold, EmployeeStatus } from './utils/constants';

import { Inbox } from './components/main/Inbox';
import { Employees } from './components/employees/Employees';

import { Teams } from "./components/teams/Teams";
import { Knodules } from "./components/knodules/Knodules";
import { Dreams } from "./components/dreams/dreams";
import { DreamFlow  } from "./components/dreamflow/dreamflow";
import { News  } from "./components/news/news";

import { TokensUX } from './components/tokens/tokens';

import { Subscribe } from './components/onboarding/Subscribe';
import { TermsConditions } from './components/onboarding/TermsConditions';

import { UserUI } from './utils/data_types';

import { isMobileBrowser } from './utils/utils';

import { Loading } from './components/main/Loading';

import { logoPath } from './utils/config';

import { prompt_cost, completion_cost } from './utils/constants';

const { Content, Sider, Header } = Layout;

const avatarOptions = Array.from({ length: 50 }, (_, i) => i+1).map(i=>`images/avatars/avatar-${i}-svgrepo-com.svg`)

type AppProps = {
    pca: IPublicClientApplication;
};

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
    
): MenuItem => {
    return {
        key,
        icon,
        children,
        label,
        type
    } as MenuItem;
};

interface DescriptionItemProps {
    title: string;
    content: React.ReactNode;
}
const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
    <div className="site-description-item-profile-wrapper">
      <p className="site-description-item-profile-p-label">{title}:</p>
      {content}
    </div>
  );

const Main = (vars: { path: string}) => {
    const { Dragger } = Upload;

    const navigate = useNavigate();
    const location = useLocation();
  
    const [bearerToken, setBearerToken] = useState('');
    const [page, setPage] = useState<string>('personal');
    const [permissions, setPermissions] = useState<any>({});

    // const [schema, setSchema] = useState<Schema | undefined>();
    
    const [items, setItems] = useState<MenuItem[]>([]);

    const { instance } = useMsal();
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const [user, setUser] = useState<UserUI>();
    const [pt100, setPt100] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const [entityCosts, setEntityCosts] = useState<number>(0);
    const [costsTable, setCostsTable] = useState<Record<string,number>>({});

    const [labels, setLabels] = useState<Record<string,string>>({});

    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [isMobile, _] = useState<boolean>(isMobileBrowser());

    const [openDrawer, setOpenDrawer] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const [contextList, setContextList] = useState<any[]>([]);

    const menuRef = useRef<HTMLDivElement | null>(null);
    const menuRef2 = useRef<HTMLDivElement | null>(null);

    const [showAvatarSelection, setShowAvatarSelection] = useState(false);

    const [messageCounter, setMessageCounter] = useState(0);

    const uint8ArrayToBase64 = (buffer: Uint8Array): string => {
        let binary = '';
        const len = buffer.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(buffer[i]);
        }
        return window.btoa(binary);
    }

    const props: UploadProps = {
        name: 'file',
        accept:'image/*',
        multiple: false,
        showUploadList: true,
        customRequest: async (options: any) => { 
            
            // eslint-disable-next-line
            const { onSuccess, onError, file, onProgress } = options;
            const buffer = await (file as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);
            const base64String = uint8ArrayToBase64(bytes);

            if(onSuccess){
                axios.post(
                    `${azure_function_url}/NCID?command=id_avatar_update`,
                    {
                        data: `data:${file.type};base64,${base64String}`
                    },
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': bearerToken
                        }
                    }
                ).then(x => {
                    update();
                    setShowAvatarSelection(false);
                    onSuccess("Ok");
                }).catch(err => {console.log(err);message.error(err.toString())})
            }            
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            }
            else if (status === 'removed') {
                message.success(`${info.file.name} file removed successfully.`);
            }
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    const update = (token?: string) => {

        if(token || bearerToken){    
            
            axios.get(
                `${azure_function_url}/NCID?command=id`,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        'Authorization': token || bearerToken
                    }
                }
            ).then(x => {
                const _user: UserUI = parseJSON(x.data);

                if(!_user.avatar){
                    const min = 1;
                    const max = 50;
                    _user.avatar = `images/avatars/avatar-${Math.floor(Math.random() * (max - min + 1)) + min}-svgrepo-com.svg`
                }

                setUser(_user);
                setPt100((_user.data.personal?.points?.amount || 0) >= PointThreshold);
                
                setLoading(false);
            }).catch(err => {console.log(err);message.error(err.toString())});

            axios.get(
                `${azure_function_url}/LLMModel?command=context_list`,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        'Authorization': token || bearerToken
                    }
                }
            ).then(async x => {
                setContextList(x.data);
                
                let keys: Record<string,string> = {};
                
                const _menuKeys : MenuItem[] = [];

                _menuKeys.push(getItem('Personal', 'personal', <UserOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>))
                _menuKeys.push(getItem('Dreams', 'dreams', <BulbOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>))

                const flows = await axios.get(
                    `${azure_function_url}/LLMModel?command=flow_list`, 
                    {
                        headers: {
                          'Authorization': token || bearerToken
                        }
                    });

                if(flows?.data?.length > 0 && !isMobile)
                    _menuKeys.push(getItem('Teams', 'teams', <TeamOutlined style={{ fontSize: isMobile ? '20px' : '15px' }}/>))
                setLabels(_x =>{
                    _x['personal']='Personal';
                    _x['teams']='Teams';
                    _x['dreams']='Dreams';
                    return _x;
                });

                const _subMenuKeys : MenuItem[] = [];
                

                x.data.forEach((x:any)=> {
                    keys[x.id] = x.permission;
                    _subMenuKeys.push(getItem(x.name, x.id))

                    setLabels(_x =>{
                        _x[x.id]=x.name;
                        return _x;
                    });
                });
                _subMenuKeys.push(getItem('Add', 'add', <PlusCircleOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>))
                setPermissions(keys);

                _menuKeys.push(getItem('Knodules', 'knodules', <RadarChartOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>, _subMenuKeys))

                setItems(_menuKeys)
            }).catch(err => {console.log(err);message.error(err.toString())})

            let messageCount = 0;
            
            axios.get(
                `${azure_function_url}/NCID?command=employees_invitations`,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        'Authorization': token || bearerToken
                    }
                }
            ).then(x => {
                const invitations = parseJSON(x.data);
                messageCount += invitations?.length
    
                axios.get(
                    `${azure_function_url}/NCID?command=clients_invitations`,
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': token || bearerToken
                        }
                    }
                ).then(x => {
                    const invitations = parseJSON(x.data);
                    messageCount += invitations?.length

                    setMessageCounter(messageCount);
                }).catch(err => {console.log(err);message.error(err.toString())});
    
            }).catch(err => {console.log(err);message.error(err.toString())});

            if(user?.entity?.permission === EmployeeStatus.OWNER || user?.entity?.permission === EmployeeStatus.ADMIN){

                axios.get(
                    `${azure_function_url}/LLMModel?command=costs_get`,
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': token || bearerToken
                        }
                    }
                ).then(x => {
                    if(x.data){
                        const costsTable = x.data.reduce((acc:any, item:any) => {
                            acc[item['userid']] = parseInt(item.prompt_tokens) * prompt_cost + parseInt(item.completion_tokens) * completion_cost;
                            return acc;
                        }, {} as Record<string, any>);

                        const totalCost = x.data.reduce((acc:any, item:any) => {
                            acc += parseInt(item.prompt_tokens) * prompt_cost + parseInt(item.completion_tokens) * completion_cost;
                            return acc;
                        }, 0);
                        setCostsTable(costsTable)
                        setEntityCosts(totalCost)
                    }
                }).catch(err => {console.log(err);message.error(err.toString())});
            }
        }
    }

    const reload = () => {
        console.log(loginRequest)
        msalInstance.acquireTokenRedirect({
            ...loginRequest,
        });
    }

    const getToken = () => {

        try{
            const account = msalInstance.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account,
            }).then(response => {
                const bearer = `Bearer ${response.accessToken}`;
                setBearerToken(bearer);
                update(bearer);  
            });
        }
        catch(err){
            console.log(err)
            throw err;
        }
    }
    
    useEffect(() => {
        getToken();
        const id = setInterval(() => {
                getToken();
        }, 1000 * 60 * 15); 

        return () => clearInterval(id); 
    // eslint-disable-next-line
    }, []);

    useEffect(()=> {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id'); // Replace 'myParam' with your query parameter name
        
        if(id){
            if(id === 'profile'){
                setOpenDrawer(true);
            }
            else{
                setPage(id);
                if(vars.path === 'teams'){
                    navigate(`/teams`);
                }
                else if(vars.path === 'dreams'){
                    navigate(`/dreams?id=${id}`);
                }
                else{
                    if(id === 'personal')
                        navigate('/');
                    else
                        navigate(`/knodule?id=${id}`);
                }
            }
        }
    },[location.search])

    const onClick: MenuProps['onClick'] = (e) => {
        setCollapsed(true);

        switch(e.key){
            case 'reload':
                update();
                break;

            case 'subscription':
                console.log('subscription');
                axios.post(
                    `${azure_function_url}/LLMModel?command=payments_dashboard`,
                    {
                        redirect_url: window.location.origin
                    },
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': bearerToken
                        }
                    }
                ).then(x => {
                    window.location.replace(x.data.url);
                }).catch(err => {console.log(err);message.error(err.toString())})
                break;
            case 'logout':
                console.log('logout');
                instance.logoutRedirect();
                break;

            case 'add':
                setOpenModal(true);
                break;

            case 'teams':
                setPage(e.key);
                navigate(`/teams?id=${e.key}`);
                break;

            case 'dreams':
                setPage(e.key);
                navigate(`/dreams?id=${e.key}`);
                break;

            default:
                setPage(e.key);
                if(e.key === 'personal')
                    navigate('/');
                else
                    navigate(`/knodule?id=${e.key}`);
                break;
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setCollapsed(true);
            }
        };
    
        if (!collapsed) {
            document.addEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [collapsed, menuRef, menuRef2]);
    
    return (
        loading ?
            <Loading />
        :
        <>
            <Header 
                style={{ 
                    padding: 0, 
                    background: 'rgba(37, 39, 40, 0.8)', 
                    position: 'fixed', 
                    width:'100%', 
                    zIndex:1,
                    borderBottom: '1px solid white'                    
                }}
            >
                <a
                    style={{
                        left:'15px',
                        top:'-6px',
                        position: 'relative'
                    }}

                    href='/'
                >
                    <Image
                        preview={false}
                        height={35 * 0.75}
                        width={140 * 0.75}
                        src={logoPath}
                    />
                </a>
                <Breadcrumb
                    style={{ position:'fixed'}}
                    items={[{ title: labels[page] }]} 
                />
                
                <Button
                    type="text"
                    icon={collapsed ? <Badge count={messageCounter} size="small"><MenuOutlined style={{color:'white'}} /></Badge> : <CloseOutlined />}
                    onClick={() => {
                        setCollapsed(!collapsed);
                        update();
                    }}
                    style={{
                        color: 'white',
                        fontSize: '16px',
                        float:'right',
                        width: 64,
                        height: 64,
                    }}
                />
            </Header>
            <Layout 
                style={{ 
                    height: '100vmh', 
                    paddingTop:'64px'
                }}
            >
                <div ref={menuRef}>
                    <Sider
                        
                        width={collapsed ? undefined : '100vw'}
                        collapsed={collapsed}
                        collapsedWidth={0}
                        style={{
                            transition: 'width 0.2s',
                            overflow: 'auto', 
                            backgroundColor: 'none',
                            background:'none',
                            margin: '0 0 0 0 0',
                            position: 'fixed',
                            zIndex: 100,
                            left: collapsed ? '-50px' : undefined
                        }}
                    >
                
                        <Menu 
                            onClick={onClick} 
                            style={
                                    collapsed ?
                                    { 
                                        width: 45,
                                        height:'calc(100vh - 154px)'
                                    }
                                    :
                                    {
                                        right: 0,
                                        position: 'fixed',

                                        visibility: 'visible', 
                                        backgroundColor: 'rgba(37, 39, 40, 0.8)',
                                        background:'rgba(37, 39, 40, 0.8)',
                                        paddingTop:'20px',
                                        height:'calc(100vh - 154px)',
                                        fontSize: '25px',
                                        fontWeight: 100,
                                        fontFamily: 'Poppins,sans-serif',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                        overflow: 'hidden',
                                        color: 'white',

                                        maxWidth: isMobile ? undefined : '400px',

                                        flex: 1,
                                        overflowY: 'auto'
                                    }
                            } 
                            mode="inline"
                            items={items.concat(user?.entity?.permission === EmployeeStatus.OWNER ? getItem('Subscription', 'subscription', <ShoppingCartOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>) : null).filter(x=>x).map(x => {
                                
                                let tmp: any = {...x};
                                if(collapsed)
                                    tmp.label = undefined;
                                
                                return tmp;
                            })} 
                        />
                        
                    </Sider>

                    <div                         
                        style={
                            collapsed ?
                            { 
                                position:'fixed',
                                left:-100,

                            }
                            :
                            { 
                                display: 'flex', 
                                width: isMobile ? '100%' : '400px',
                                right: 0,
                                bottom: 0,
                                zIndex: 100,
                                position: 'fixed',            
                            }
                        }
                    >
                        <Button onClick={() => { setOpenDrawer(true) } } icon={<Badge count={messageCounter} size="small"><InfoCircleOutlined style={{fontSize:'30px', color:'white'}}/></Badge>} style={{ flex: 1, height: '90px', borderRadius:0, borderTopColor:'rgba(255, 255, 255, 0.6)', borderRightColor:'rgba(255, 255, 255, 0.6)' }} type="primary" />
                        <Button onClick={() => { instance.logoutRedirect();}} icon={<LogoutOutlined style={{fontSize:'30px', }}/>} style={{ flex: 1, height: '90px', borderRadius:0, borderTopColor:'rgba(255, 255, 255, 0.6)' }} type="primary" />
                    </div>
                </div>
 
                <Layout style={{ padding: '0px 0px 0px 0px', filter: (!collapsed || openDrawer || openModal) ? 'blur(10px)' : 'none' }}>
                    <Content style={{
                        height:'100vmh',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                            <div 
                                style={ isMobile ? { width: '97%' } : { width: '100%' } }
                            >
                            {
                                page === 'teams' || vars.path === 'teams' ? <Teams isMobile={isMobile} bearerToken={bearerToken} user={user} /> :
                                page === 'dreams' || vars.path === 'dreams' ? <Dreams isMobile={isMobile} bearerToken={bearerToken} user={user} context={page === 'dreams' ? user?.id : page} contextList={contextList} permission={page === 'personal' ? undefined : permissions[page]}/> :
                                page === 'dreamflow' || vars.path === 'dreamflow' ? <DreamFlow isMobile={isMobile} bearerToken={bearerToken} user={user} context={page === 'dreamflow' ? user?.id : page} contextList={contextList} permission={page === 'personal' ? undefined : permissions[page]}/> :
                                page === 'news' || vars.path === 'news' ? <News isMobile={isMobile} bearerToken={bearerToken} user={user} context={page === 'news' ? user?.id : page} contextList={contextList} permission={page === 'personal' ? undefined : permissions[page]}/> :
                                
                                page === 'tokens' || vars.path === 'tokens' ? <TokensUX isMobile={isMobile} bearerToken={bearerToken} user={user} /> :
                                <Knodules isMobile={isMobile} bearerToken={bearerToken} user={user} context={page === 'personal' ? user?.id : page} permission={page === 'personal' ? undefined : permissions[page]}/>
                            }
                            </div>
                    </Content>
                </Layout>
            </Layout>
            <div ref={menuRef2}>
                <Drawer 
                    title="Profile" 
                    width={'50%'}
                    placement="right" 
                    onClose={() => {
                        const queryParams = new URLSearchParams(location.search);
                        const id = queryParams.get('id'); // Replace 'myParam' with your query parameter name
                        if(id === 'profile')
                            navigate('/');
        
                         setOpenDrawer(false)
                    }}
                    open={openDrawer}
                    extra={<Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => reload() }></Button> }
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 24 }}>
                        <Avatar 
                            size={180} 
                            src={user?.avatar} // Replace with your user's avatar URL
                            alt={`${user?.data.personal?.name.firstName} ${user?.data.personal?.name.lastName}`}
                        />
                        <div style={{ marginTop: '10px' }}> {/* Adds spacing between the avatar and the button */}
                            <Button 
                                type="primary" 
                                onClick={() => setShowAvatarSelection(!showAvatarSelection)}
                            >
                                {!showAvatarSelection ? 'Select New Avatar' : 'Close'}
                            </Button>
                        </div>
                    </div>
                    {showAvatarSelection && (
                        <div style={{ marginBottom: 24 }}>
                            <Divider />
                            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                {avatarOptions.map((avatarUrl, index) => (
                                    <div
                                        key={index}
                                        style={{ padding: '5px', cursor: 'pointer' }}
                                        onClick={() => {
                                            console.log(avatarUrl)
                                            axios.post(
                                                `${azure_function_url}/NCID?command=id_avatar_update`,
                                                {
                                                    data: avatarUrl
                                                },
                                                {
                                                    maxContentLength: Number.POSITIVE_INFINITY,
                                                    headers: {
                                                        'Authorization': bearerToken
                                                    }
                                                }
                                            ).then(x => {
                                                update();
                                                setShowAvatarSelection(false);
                                            }).catch(err => {console.log(err);message.error(err.toString())})
                                        }}
                                    >
                                        <Avatar
                                            size={64}
                                            src={avatarUrl}
                                            alt={`Avatar ${index + 1}`}
                                        />
                                    </div>
                                ))}
                            </div>
                            <Divider />
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                                </p>
                                <p className="ant-upload-text">{ isMobile ? 'Click to add an Avatar' : 'Click or drag an Avatar'}</p>
                            </Dragger>
                        </div>
                    )}
                    <br></br>
                    <Row>
                        {user?.entity?.permission === EmployeeStatus.OWNER || user?.entity?.permission === EmployeeStatus.ADMIN ?
                        <>
                            <Col span={8}>
                                <DescriptionItem title="First Name" content={user?.data.personal?.name.firstName} />
                            </Col>
                            <Col span={8}>
                                <DescriptionItem title="Last Name" content={user?.data.personal?.name.lastName} />
                            </Col>
                            <Col span={8}>
                                <DescriptionItem title="Spend" content={`AUD ${costsTable[user?.id || '']?.toFixed(2)}`} />
                            </Col>
                        </>
                        :
                        <>
                            <Col span={12}>
                                <DescriptionItem title="First Name" content={user?.data.personal?.name.firstName} />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem title="Last Name" content={user?.data.personal?.name.lastName} />
                            </Col>
                        </>
                        }
                    </Row>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="ID" content={user?.id} />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="Email" content={user?.email} />
                        </Col>
                        
                    </Row>                    
                    <br></br>
                    <Row>
                        <Col span={24}><Inbox bearerToken={bearerToken} user={user} reload={() => {reload()}}/></Col>
                    </Row>
                    {
                    user?.entity?.id ?
                    <>
                    <br></br>
                    <p className="site-description-item-profile-p">Company</p>
                    <Row>
                        <Col span={user?.entity?.permission === EmployeeStatus.OWNER || user?.entity?.permission === EmployeeStatus.ADMIN ? 6 : 8}>
                            <DescriptionItem title="Name" content={user?.entity?.name} />
                        </Col>
                        <Col span={user?.entity?.permission === EmployeeStatus.OWNER || user?.entity?.permission === EmployeeStatus.ADMIN ? 6 : 8}>
                            <DescriptionItem title="ABN" content={user?.entity?.reference} />
                        </Col>
                        <Col span={user?.entity?.permission === EmployeeStatus.OWNER || user?.entity?.permission === EmployeeStatus.ADMIN ? 6 : 8}>
                            <DescriptionItem title="Status" content={user?.entity?.permission === EmployeeStatus.WORKER ? 'Employee' : user?.entity?.permission === EmployeeStatus.OWNER ? 'Owner' : user?.entity?.permission === EmployeeStatus.ADMIN ? 'Admin' : 'Denied' } />
                        </Col>
                        { user?.entity?.permission === EmployeeStatus.OWNER || user?.entity?.permission === EmployeeStatus.ADMIN ? 
                        <Col span={6}>
                            <DescriptionItem title="Aggregated Spend" content={`AUD ${entityCosts?.toFixed(2)}`} />
                        </Col>
                        :<></>}
                    </Row>
                    </>
                    :
                    <></>}
                    {
                    user?.entity?.permission === EmployeeStatus.OWNER ?
                        <>
                        <br></br>
                        <Row>
                            <Col span={24}><Employees bearerToken={bearerToken} user={user}/></Col>
                        </Row>
                        </>
                    :
                        <></>
                    }
                </Drawer>
            </div>

            <Modal okType={'default'} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }} title="Create Knodule" width={1000} onCancel={() => setOpenModal(false)} onOk={() => setOpenModal(false)}  closable={true} open={openModal}>
                <Card>
                    <Form 
                        {...{
                            labelCol: { span: 8 },
                            wrapperCol: { span: 16 },
                        }}
                        onFinish={(values:any) => {
                            const name = values.name;
                            const reference = values.description;
                            const data = '';
                            const pkg = {name: name, reference: reference, data: data}
                            axios.post(
                                `${azure_function_url}/LLMModel?command=group_create`,
                                pkg, 
                                {
                                    headers: { 'Authorization': bearerToken }
                                }
                            ).then(response => {
                                setOpenModal(false);
                            }).catch(error => {
                                console.error('Purchase error:', error);
                                setOpenModal(false);
                            });
                            
                        }}
                    >
                        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the Knodule name!' }]}>
                            <Input placeholder="Knodule Name" />
                        </Form.Item>
                        <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input the Knodule description!' }]}>
                            <Input placeholder="Knodule Description" />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
                            <Button type="primary" htmlType="submit">
                                Create
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>
        </>
    );
}

const CheckAccess = (vars: { path: string}) => {
    const [isSubscribed, setSubscribed] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [isAcceptedTC, setIsAcceptedTC] = useState(false);
    const [bearerToken, setBearerToken] = useState('');

    const [user, setUser] = useState<UserUI>();

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const location = useLocation();

    // Parse the query string
    const queryParams = new URLSearchParams(location.search);
    const update_subscriptions = queryParams.get('update_subscriptions'); // Replace 'myParam' with your query parameter name

    const update = () => {
        const account = msalInstance.getActiveAccount();
        if(account){
            msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then(response => {
                const bearer = `Bearer ${response.accessToken}`;
                setBearerToken(bearer);

                axios.get(
                    `${azure_function_url}/NCID?command=alive`,
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': bearer
                        }
                    }
                ).then(x => {
                    // message.success('SIKE has entered...')
                });

                axios.get(
                    `${azure_function_url}/NCID?command=id`,
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': bearer
                        }
                    }
                ).then(x => {
                    const _user: UserUI = parseJSON(x.data);

                    if(!_user.avatar){
                        const min = 1;
                        const max = 50;
                        _user.avatar = `images/avatars/avatar-${Math.floor(Math.random() * (max - min + 1)) + min}-svgrepo-com.svg`
                    }

                    setIsAcceptedTC(_user.terms_accepted === 1);
                    if(_user.terms_accepted === 1){

                        if(update_subscriptions?.toLowerCase() === 'true'){
                            axios.get(
                                `${azure_function_url}/LLMModel?command=payments_subcriptions`,
                                {
                                    maxContentLength: Number.POSITIVE_INFINITY,
                                    headers: {
                                        'Authorization': bearer
                                    }
                                }
                            ).then(d => {

                                axios.get(
                                    `${azure_function_url}/LLMModel?command=payments_is_subscribed`,
                                    {
                                        maxContentLength: Number.POSITIVE_INFINITY,
                                        headers: {
                                            'Authorization': bearer
                                        }
                                    }
                                ).then(subscribedResult => {
                                    console.log(subscribedResult)

                                    // if(vars.isAdminPage){
                                    //     const _isSubscribed = subscribedResult.data?.toLowerCase() === 'true';//_user.stripe_active === 1;
        
                                    //     setSubscribed(_isSubscribed);
                                    // }
                                    // else{
                                        const _isSubscribed = subscribedResult.data?.toLowerCase() === 'true';//_user.stripe_active === 1;
                                        const _needsSubscription = _user.entity?.permission === EmployeeStatus.OWNER;
        
                                        if(_needsSubscription)
                                            setSubscribed(_isSubscribed);
                                        else
                                            setSubscribed(true);
                                    // }

                                    setLoading(false);
                                });
                            }).catch(err => {console.log(err); setLoading(false);});
                        }
                        else{

                            axios.get(
                                `${azure_function_url}/LLMModel?command=payments_is_subscribed`,
                                {
                                    maxContentLength: Number.POSITIVE_INFINITY,
                                    headers: {
                                        'Authorization': bearer
                                    }
                                }
                            ).then(subscribedResult => {
                                const _isSubscribed = subscribedResult.data?.toLowerCase() === 'true';    
                                setSubscribed(_isSubscribed);
                                setLoading(false);
                            }).catch(err => {console.log(err);message.error(err.toString()); setLoading(false);});
                        }
                    }
                    else{
                        setLoading(false);
                    }

                    setUser(_user);
                    
                    
                }).catch(err => {console.log(err);message.error(err.toString())});
            }).catch(err => {
                console.log(err)
            });
        }
    }

    useEffect(() => {
        try{
            if(isMobileBrowser())
                window.scrollTo(0,1);
        }
        catch{}

        update();
    }, []);

    return (
        loading ? <Loading/>
        :
        
            !isAcceptedTC?
                <TermsConditions bearerToken={bearerToken} update={update}/>
            : isSubscribed?
                <Main path={vars.path}/>
            :                    
                <Subscribe user={user} bearerToken={bearerToken} />
        
    );
}

const ErrorComponent: React.FC<MsalAuthenticationResult> = ({error}) => {
    return <div>An Error Occurred: {error ? JSON.stringify(error) : "unknown error"}</div>;
}

const App = ({ pca }: AppProps) => {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

 
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest} 
                errorComponent={ErrorComponent} 
                loadingComponent={() => (<div>loading...</div>)}
            >
                <Routes>
                    <Route path='/' element={<CheckAccess path={'/'}/>}/>
                    <Route path='/knodule' element={<CheckAccess path={'knodule'}/>}/>
                    <Route path='/teams' element={<CheckAccess path={'teams'}/>}/>
                    <Route path='/dreams' element={<CheckAccess path={'dreams'}/>}/>
                    <Route path='/dreamflow' element={<CheckAccess path={'dreamflow'}/>}/>
                    <Route path='/news' element={<CheckAccess path={'news'}/>}/>
                    <Route path='/tokens' element={<CheckAccess path={'tokens'}/>}/>
                    <Route path='/message' element={'MESSAGE'}/>
                </Routes>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

export default App;
// App.tsx

import React from 'react';

import { Tour, FormInstance, Alert, Spin, Avatar, Select, Popconfirm, Tag, TreeSelect, Divider, Modal, Card, Tooltip, FloatButton, Input, Button, List, Form, Table, Space, Tabs, message, Row } from 'antd';
import type { TourProps } from 'antd';
import { ExportOutlined, FormOutlined, LinkOutlined, MinusCircleOutlined, InfoCircleOutlined, BarsOutlined, RedoOutlined, SafetyCertificateOutlined, SearchOutlined, FilePdfOutlined, FileWordOutlined, CopyOutlined, DownOutlined, UpOutlined, SendOutlined, LoadingOutlined, DeleteOutlined} from '@ant-design/icons';

import { prompt_cost, completion_cost } from '../../utils/constants';

import hljs from 'highlight.js';
import { marked } from 'marked';
import 'highlight.js/styles/github.css';  // Or any other style you prefer

import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

import { getPrompt, prompts, gptModel } from '../../utils/prompts';

const { CheckableTag } = Tag;

const renderMarkdown = (_markdownContent: any) => {

    let markdownContent = '';
    if(Array.isArray(_markdownContent))
        markdownContent = _markdownContent.join('\n');
    else
        markdownContent = _markdownContent;
    
    if(markdownContent.startsWith('```markdown'))
        markdownContent = markdownContent.substring('```markdown'.length);

    if(markdownContent.endsWith('```'))
        markdownContent = markdownContent.substring(0, markdownContent.length - '```'.length);
    
    // Step 1: Convert markdown to HTML
    const htmlContent = marked(markdownContent.toString());

    // Step 2: Parse and highlight code blocks
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find all <pre> elements containing <code> (i.e., code blocks)
    const codeBlockElements = doc.querySelectorAll('pre > code');

    codeBlockElements.forEach(codeElem => {
        const rawCode = codeElem.textContent || '';
        const highlighted = hljs.highlightAuto(rawCode).value;
        codeElem.innerHTML = highlighted;

        // Wrap the code block inside a div with a 'code-card' class
        const wrapper = doc.createElement('div');
        wrapper.className = 'code-card';
        const parentPre = codeElem.parentElement;
        if (parentPre) {
            parentPre.parentNode?.replaceChild(wrapper, parentPre);
            wrapper.appendChild(parentPre);
        }
    });

    // Step 3: Return the updated HTML
    return doc.body.innerHTML;
};

const IconText = ({ icon, text, onClick, color, size }: { icon: React.FC; text?: string; onClick?: any; color?:string; size?: string }) => (
    <a style={{color: 'grey'}}>
        <Tooltip title= {text}>
            <Space onClick={onClick||undefined}>
                <span style={{ 
                    fontSize: size || '24px',
                    alignItems: 'flex-start',
                    marginBlockStart: undefined,
                    color: color
                }}>
                    {React.createElement(icon)}
                </span>
            </Space>
        </Tooltip>
    </a>
);

const uniqueObjects = (objects: any[]) => objects?.reduce((unique, obj) => {
    if (!unique.some((o:any) => o.name === obj.name)) {
      unique.push(obj);
    }
    return unique;
}, [] as any[]);

const isValidJson = (jsonString?: string): boolean => {
    try {
        if(!jsonString) return false;

        JSON.parse(jsonString);
        return true;
    } catch (e) {
          return false;
    }
};

const copyHtmlUsingExecCommand = (htmlContent: string) => {
    const container = document.createElement('div');
    container.innerHTML = htmlContent;
    container.style.position = 'fixed'; // Avoids scrolling to bottom
    document.body.appendChild(container);
    
    const ww = window.getSelection();
    ww?.removeAllRanges();
    const range = document.createRange();
    range.selectNode(container);
    ww?.addRange(range);
  
    try {
      document.execCommand('copy');
      console.log('Content copied');
    } catch (err) {
      console.error('Failed to copy', err);
    }
  
    document.body.removeChild(container);
}

export const ShowOutput = (params: { 
    input: string | string[],
    content: string | string[],
    prompts: any[] | undefined,
    author: string,
    agent_reference: string,
    prompt_tokens: number,
    completion_tokens: number,
    runningTime: number | undefined,
    timestamp: Date,
    isSigned: boolean,
    isPublic: boolean,
    sourceDocuments: any[] | undefined,
    tags: any[] | undefined,
    docsearch: string | undefined,
    label: string,
    i: number,
    totalMessages: number,
    user: any,
    isMobile: boolean,
    delete: () => void,
    movePrompt: () => void,
    microSign: () => void,
    publish: () => void,
    openSources: () => void,
    download: (id: string, name: string, type: string) => void,
    references: {
        top: React.MutableRefObject<HTMLDivElement | null>,
        end: React.MutableRefObject<HTMLDivElement | null>,
        redo: React.MutableRefObject<HTMLDivElement | null>,
        microsig: React.MutableRefObject<HTMLDivElement | null>,
        publish: React.MutableRefObject<HTMLDivElement | null>,
        sources: React.MutableRefObject<HTMLDivElement | null>,
        copy: React.MutableRefObject<HTMLDivElement | null>,
        messages: React.MutableRefObject<HTMLDivElement | null>
    }
}) => {
    
    return <div style={{ flexGrow: 1 }}>
        <div style={{ 
                display: params.isMobile ? undefined : 'flex', 
                justifyContent: 'space-between',
                alignItems: 'start' 
        }}>
            <List.Item.Meta 
                avatar={ params.author == 'User' ? <Avatar src={params.user.avatar} /> : <Avatar src="sike_logo.png" /> }
                title={
                    <span style={{ fontSize: '20px' }}>
                        {params.author == 'User' ? params.user.data.personal.name.firstName : prompts[params.agent_reference] ? `${params.label || 'SIKE'} | ` + prompts[params.agent_reference].label : `${params.label || 'SIKE'}`}
                    </span>
                }
                description={
                    params.isMobile ?
                    `${params.runningTime ? 'Time: ' + (params.runningTime / 1000).toLocaleString() + ' sec' : params.timestamp.toLocaleString()}` + (params.totalMessages > 1 && params.author !== 'User' ? ` | AUD ${params.prompt_tokens && params.completion_tokens ? (params.prompt_tokens * prompt_cost + params.completion_tokens * completion_cost).toFixed(2) : 0}` : '')
                    :
                    `${params.runningTime ? 'Running Time: ' + (params.runningTime / 1000).toLocaleString() + ' sec' : params.timestamp.toLocaleString()}` + (params.totalMessages > 1 && params.author !== 'User' ? ` | Token Spend: AUD ${params.prompt_tokens && params.completion_tokens ? (params.prompt_tokens * prompt_cost + params.completion_tokens * completion_cost).toFixed(2) : 0}` : '')
                } 
            />
            <Space>
            {
                (
                    params.author == 'User' ? 
                [
                    <Popconfirm
                        title="Delete Message"
                        description="Would you like to delete the message?"
                        onConfirm={params.delete} 
                        onCancel={() => { }}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <IconText 
                            size={params.isMobile ? 'small' : undefined}
                            icon={DeleteOutlined} 
                        />
                    </Popconfirm>,
                    <div style={{
                        borderLeft: '1px solid #ddd',
                        height: '20px', // Set a fixed height for the divider
                        alignSelf: 'center', // Align the divider to center vertically
                        marginLeft: 8,
                        marginRight: 8,
                    }} />,
                    <div ref={params.references.redo}>
                        <IconText 
                            size={params.isMobile ? 'small' : undefined}
                            icon={FormOutlined} 
                            text={'Move to Prompt'} 
                            onClick={params.movePrompt} 
                        />
                    </div>,
                    <div style={{
                        borderLeft: '1px solid #ddd',
                        height: '20px', // Set a fixed height for the divider
                        alignSelf: 'center', // Align the divider to center vertically
                        marginLeft: 8,
                        marginRight: 8,
                    }} />,
                    <IconText 
                        icon={CopyOutlined} 
                        size={params.isMobile ? 'small' : undefined}
                        text={'Copy'} 
                        onClick={() => { 
                            if(Array.isArray(params.content))
                                copyHtmlUsingExecCommand(marked(params.content.join('\n')));
                                
                            else
                                copyHtmlUsingExecCommand(marked(params.content));
                                
                            message.success(`Copied!`);
                        }
                        } 
                    />
                ].map((x, i)=> { return {...x, key: i} })
                : 
                [
                    <Popconfirm
                        title="Microsignature"
                        description="Would you like to microsign this message?"
                        onConfirm={params.microSign}
                        onCancel={() => { }}
                        okText="Sign"
                        cancelText="Cancel"
                    >
                        <div ref={params.references.microsig}>
                            <IconText 
                                icon={SafetyCertificateOutlined}
                                color={params.isSigned ? '#52c41a' : undefined} 
                                size={params.isMobile ? 'small' : undefined}
                                text={'Microsign'}
                            />
                        </div>
                    </Popconfirm>,
                    <div style={{
                        borderLeft: '1px solid #ddd',
                        height: '20px', // Set a fixed height for the divider
                        alignSelf: 'center', // Align the divider to center vertically
                        marginLeft: 8,
                        marginRight: 8,
                    }} />,
                    <Popconfirm
                        title="Public"
                        description="Would you like to make this message public?"
                        onConfirm={params.publish}
                        onCancel={() => { }}
                        okText="Publish"
                        cancelText="Cancel"
                    >
                        <div ref={params.references.publish}>
                            <IconText 
                                icon={ExportOutlined}
                                color={params.isPublic ? '#52c41a' : undefined} 
                                size={params.isMobile ? 'small' : undefined}
                                text={'Publish'}
                            />
                        </div>
                    </Popconfirm>,
                    <div style={{
                        borderLeft: '1px solid #ddd',
                        height: '20px', // Set a fixed height for the divider
                        alignSelf: 'center', // Align the divider to center vertically
                        marginLeft: 8,
                        marginRight: 8,
                    }} />,
                    <div ref={params.references.sources}>
                        <IconText 
                            icon={BarsOutlined} 
                            text={'Sources'}
                            size={params.isMobile ? 'small' : undefined}
                            onClick={params.openSources}
                        />
                    </div>,
                    <div style={{
                        borderLeft: '1px solid #ddd',
                        height: '20px', // Set a fixed height for the divider
                        alignSelf: 'center', // Align the divider to center vertically
                        marginLeft: 8,
                        marginRight: 8,
                    }} />
                    ,
                    <div ref={params.references.redo}>
                        <IconText 
                            icon={FormOutlined} 
                            size={params.isMobile ? 'small' : undefined}
                            text={'Move Response to Prompt'} 
                            onClick={params.movePrompt} 
                        />
                    </div>,
                    <div style={{
                        borderLeft: '1px solid #ddd',
                        height: '20px', // Set a fixed height for the divider
                        alignSelf: 'center', // Align the divider to center vertically
                        marginLeft: 8,
                        marginRight: 8,
                    }} />,
                    <div ref={params.references.copy}>
                    <IconText 
                        icon={CopyOutlined} 
                        text={'Copy'} 
                        size={params.isMobile ? 'small' : undefined}
                        onClick={() => { 
                            if(Array.isArray(params.content))
                                copyHtmlUsingExecCommand(marked(params.content.join('\n')));
                            else
                                copyHtmlUsingExecCommand(marked(params.content));
                            message.success(`Copied!`);
                        }} 
                    />
                    </div>
                ].map((x, i)=> { return {...x, key: i} })
                )
            }
            </Space>
        </div>
        {
            params.content ?
            <>
            {0 === params.i && (<div ref={params.references.top} />)}
            
            { params.author == 'User' && Array.isArray(params.prompts) && params.prompts.length > 1 ? 
            <List
                size="small"
                bordered
                dataSource={params.prompts}
                renderItem={(item) => 
                    <List.Item>
                        <div style={{ flexGrow: 1 , wordWrap: 'break-word'}}>
                            <List.Item.Meta 
                                title={
                                    <span style={{ fontSize: '20px', wordWrap: 'break-word' }}>
                                        {item.prompt}
                                    </span>
                                }
                                description={
                                    params.isMobile ?
                                    `${'Time: ' + ((new Date(item.finish).getTime() -  new Date(item.start).getTime())/ 1000).toLocaleString() + ' sec'}` + ` | AUD ${(item.tokens.prompt * prompt_cost + item.tokens.completion * completion_cost).toFixed(2)}`
                                    :
                                    `${'Running Time: ' + ((new Date(item.finish).getTime() -  new Date(item.start).getTime())/ 1000).toLocaleString() + ' sec'}` + ` | Token Spend: AUD ${(item.tokens.prompt * prompt_cost + item.tokens.completion * completion_cost).toFixed(2)}`
                                }
                            />
                            {
                                params.isMobile ?
                                
                                <div dangerouslySetInnerHTML={{ __html: renderMarkdown(item.instructions) }} ></div>
                                :
                                <JsonView collapsed={1} src={item} />
                            }
                        </div>
                    </List.Item>
                }
            />
            : params.author == 'User' && Array.isArray(params.input) && params.input.length > 1 ? 
            <List
                size="small"
                bordered
                dataSource={params.input}
                renderItem={(item) => 
                    <List.Item>
                        <div style={{ flexGrow: 1 , wordWrap: 'break-word'}}>
                            <JsonView collapsed={1} src={item} />    
                        </div>
                    </List.Item>
                }
            />
            :
            <div 
                ref={params.references.messages} 
                style={{ 
                    paddingLeft: params.isMobile ? undefined : '40px', 
                    paddingRight: params.isMobile ? undefined : '40px', 
                    fontSize: '18px' ,
                    wordWrap: 'break-word'
                }} 
                dangerouslySetInnerHTML={{ __html: renderMarkdown(((typeof params.content === 'string') ||  (Array.isArray(params.content) && (params.content as any).every((element: any) => typeof element === 'string'))) ? params.content.toString() : JSON.stringify(params.content)) }} 
            />
            }
            {params.totalMessages === params.i && (<div ref={params.references.end} />)}
            </>
        :
            <>
            <Spin tip="Loading...">
                <Alert
                    message="Working on it..."
                    description="I need a second to work my magic!"
                    type="info"
                />
            </Spin>
            {params.totalMessages === params.i && (<div ref={params.references.end} />)}
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
        }
        <br></br>
        { 
            params.sourceDocuments && params.sourceDocuments?.length > 0 && params.tags ?
            <>
                <Space size={[0, 8]} >
                { 
                    Array.from(new Set(params.sourceDocuments.map((x:any)=>x.metadata.tags).flat()))
                    .map(
                        (x:any)=>
                            <CheckableTag style={params.tags?.includes(x) ? undefined : { backgroundColor: '#f0f0f0', borderColor: '#d9d9d9', color: '#595959' }} key={x} checked={params.tags !== undefined && params.tags?.includes(x)}>{x}
                            </CheckableTag>
                    )
                }
                </Space>
                <br></br>
                <br></br>
            </>
            :
            <></>
        }
        { 
            params.sourceDocuments ?
            <>
                { params.docsearch ? (isValidJson(params.docsearch) ? JSON.parse(params.docsearch).map((x:string, i:number)=> <Tag key={i} icon={<SearchOutlined />}>{x}</Tag>) : <Tag icon={<SearchOutlined />}>{params.docsearch}</Tag>) : <></>}
                <br></br>
                <br></br>
                <Space size={[0, 8]} wrap>
                { 
                    uniqueObjects(params.sourceDocuments?.map((x:any)=> { return { name: x?.metadata?.name, tags: x?.metadata?.tags, id: x?.metadata?.uuid, url: x?.metadata?.suid }}).flat())
                    .filter((x:any)=>x.name)
                    .map((x:any, i:number)=>
                        <a
                            key={i}
                            onClick={()=>{ if(x.id === "url") { window.open(x.url, '_blank') } else { params.download(x.id, x.name, x.type) }}}
                        >
                            <Tag icon={x.id == "url" ? <LinkOutlined /> : x.tags[0].endsWith(".pdf") ? <FilePdfOutlined />:<FileWordOutlined />} color={x.id == "url" ? "processing" : "success"}>
                                {x.name} {x.id === "url" ? ` - ${x.url}` : ''}
                            </Tag>
                        </a>
                    )
                }
                </Space>
                <br></br>
                <br></br>
            </>
            :
            <></>
        }
    </div>
}